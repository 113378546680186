<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">年份：</span>
					<el-select v-model="searchinput" clearable placeholder="请选择" style="width: 2rem;">
						<el-option label="2018" value="2018"></el-option>
						<el-option label="2019" value="2019"></el-option>
						<el-option label="2020" value="2020"></el-option>
						<el-option label="2021" value="2021"></el-option>
						<el-option label="2022" value="2022"></el-option>
						<el-option label="2023" value="2023"></el-option>
						<el-option label="2024" value="2024"></el-option>
						<el-option label="2025" value="2025"></el-option>
					</el-select>
				</div>
				<div class="search_item">
					<el-button type="primary">搜索</el-button>
					<el-button type="success" @click="addnew">新增</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showedit(scope.row)" type="warning" size="mini">编辑</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

		<!--新增、编辑-->
		<el-dialog :title="isadd?'新增记录':'编辑'" :visible.sync="showdetail" :before-close="closedb" width="40%">
			<div style="width: 100%;">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
					<el-form-item label="履职类型" prop="name" clearable required>
						<el-select v-model="ruleForm.name" placeholder="请选择">
							<el-option label="出席全体会议、常委会议情况" value="出席全体会议、常委会议情况"></el-option>
							<el-option label="参加调研、视察、考察情况" value="参加调研、视察、考察情况"></el-option>
							<el-option label="提交大会发言情况" value="提交大会发言情况"></el-option>
							<el-option label="提交提案情况" value="提交提案情况"></el-option>
							<el-option label="提交社情民意情况" value="提交社情民意情况"></el-option>
							<el-option label="被采用情况" value="被采用情况"></el-option>
							<el-option label="已批示情况" value="已批示情况"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="年度" prop="year" clearable required>
						<el-select v-model="ruleForm.year" placeholder="请选择">
							<el-option label="2018" value="2018"></el-option>
							<el-option label="2019" value="2019"></el-option>
							<el-option label="2020" value="2020"></el-option>
							<el-option label="2021" value="2021"></el-option>
							<el-option label="2022" value="2022"></el-option>
							<el-option label="2023" value="2023"></el-option>
							<el-option label="2024" value="2024"></el-option>
							<el-option label="2025" value="2025"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="标准分值" prop="fz">
						<el-input v-model="ruleForm.fz"></el-input>
					</el-form-item>					
					<el-form-item label="外部方法" prop="tzurl">
						<el-input v-model="ruleForm.tzurl" placeholder="请输入" />
					</el-form-item>
					
					<el-form-item label="履职基本内容">
						<el-input type="textarea" v-model="ruleForm.content" :rows="4"></el-input>
					</el-form-item>
				</el-form>
			</div>

			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 20px;">
				<el-button @click="closedb" size="small">取消</el-button>
				<el-button type="success" @click="qrdb()" size="small">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				baseurl: '',
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '分数',
						prop: 'fz',
						width: '120'
					},
					{
						name: '履职类型',
						prop: 'name',
						width: '250'
					},
					{
						name: '履职基本内容',
						prop: 'content',
						width: ''
					},
					{
						name: '年份',
						prop: 'year',
						width: '120'
					},
					{
						name: '排序',
						prop: 'weight',
						width: '120'
					}
				],
				tableData: [
					{
						fz:4,
						name:'提交提案情况',
						content:'',
						year:'2022',
						weight:'1'
					}
				],
				detail: '',
				isadd: true,
				ruleForm: {
					name: '',
					tzurl: '',
					fz:'',
					content:'',
					year: '',
					weight: '',
					slturl: '',
				},
				rules: {
					
				},
				classifyData: [],
				imageUrl: ''
			}
		},
		mounted() {
			this.baseurl = this.$URL
		},
		methods: {
			qrdb() {
				this.tableData.push(this.ruleForm)
				this.$message.success('操作成功')
				setTimeout(()=>{
					this.closedb()
				},1000)
				
				// this.$refs.ruleForm.validate((valid) => {
				// 	if (valid) {
				// 		this.$post({
				// 			url: this.isadd ? '/api/ad/add' : '/api/ad/edit',
				// 			params: this.ruleForm
				// 		}).then((res) => {
				// 			this.$message.success('操作成功')
				// 			this.closedb()
				// 			this.gettable()
				// 		})
				// 	} else {
				// 		console.log('error submit!!');
				// 		return false;
				// 	}
				// });
			},
			closedb() {
				this.ruleForm = {
					name: '',
					tzurl: '',
					fz:'',
					content:'',
					year: '',
					weight: '',
					slturl: '',
				}
				this.imageUrl = ''
				this.showdetail = false
			},
			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			addnew() {
				this.showdetail = true
				this.isadd = true
			},
			handleAvatarSuccess(res, file) {
				this.ruleForm.slturl = res.id
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			reset() {
				this.searchinput = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/ad/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {

					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.tableData = this.tableData.filter((item)=>{return item.name!=row.name})
						this.$message.success('删除成功')
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.ruleForm = row
				this.showdetail = true
				this.isadd = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #999;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 145px;
		height: 145px;
		line-height: 145px;
		text-align: center;
	}

	.avatar {
		width: 145px;
		height: 145px;
		display: block;
	}

	::v-deep .el-icon-circle-check:before {
		font-size: 0.2rem;
	}

	::v-deep .el-icon-circle-check {
		font-size: 0.2rem !important;
		line-height: 0.16rem;
		height: 0.16rem;
	}

	::v-deep .el-dialog__header {
		display: flex;
	}
</style>